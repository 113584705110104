export const ROUTING_LABELS = [
  "Wax",
  "Assembly & Autoclave",
  "Dip",
  "Foundry",
  "Post Foundry",
  "Outside Processing",
];

export const DEPARTMENT_LABELS = [
  "Wax",
  "Dipping",
  "Dewax",
  "Foundry",
  "Finishing",
  "Outside Processing",
  "Inspection",
];

export const DEPARTMENTS = DEPARTMENT_LABELS.map((label, id) => ({
  id: id + 1,
  label,
}));

export const ROUTINGS = [
  {
    id: 1,
    label: "Wax",
    key: "wax",
  },
  {
    id: 2,
    label: "Assembly",
    key: "assembly",
  },
  {
    id: 3,
    label: "Dip",
    key: "dip",
  },
  {
    id: 4,
    label: "Autoclave",
    key: "autoclave",
  },
  {
    id: 5,
    label: "Foundry",
    key: "foundry",
  },
  {
    id: 6,
    label: "Post Foundry & Outside Process",
    key: "post",
  },
  {
    id: 7,
    label: "Inspection",
    key: "inspection",
  },
];

export const PART_ROUTINGS = [
  {
    costcode: "0101",
    label: "Injection",
    id: 1,
  },
  {
    costcode: "0102",
    label: "Assembly",
    id: 2,
  },
  {
    costcode: "0103",
    label: "Etching",
    id: 3,
  },
  {
    costcode: "0201",
    label: "Dipping",
    id: 4,
  },
  {
    costcode: "0202",
    label: "Dipping | By Hand",
    id: 5,
  },
  {
    costcode: "0301",
    label: "Autoclave",
    id: 6,
  },
  {
    costcode: "0401",
    label: "Pouring",
    id: 7,
  },
  {
    costcode: "0501",
    label: "Cutoff - Robot",
    id: 8,
  },
  {
    costcode: "0502",
    label: "Cutoff - Chop | Friction",
    id: 9,
  },
  {
    costcode: "0503",
    label: "Blast - Spinner Hanger",
    id: 10,
  },
  {
    costcode: "0504",
    label: "Blast - Tumble",
    id: 11,
  },
  {
    costcode: "0505",
    label: "Welding",
    id: 12,
  },
  {
    costcode: "0506",
    label: "Grinding",
    id: 13,
  },
  {
    costcode: "0507",
    label: "Detailing",
    id: 14,
  },
  {
    costcode: "0508",
    label: "Heat Treat",
    id: 15,
  },
  {
    costcode: "0509",
    label: "Straightening",
    id: 16,
  },
  {
    costcode: "0535",
    label: "Blast - Sand Blasting",
    id: 17,
  },
  {
    costcode: "0701",
    label: "Inspection",
    id: 18,
  },
  {
    costcode: 225,
    label: "Drilling & Tapping",
    id: 19,
  },
];

export const OUTSIDE_ROUTINGS = [
  {
    label: "Charpy Testing",
    id: 1,
  },
  {
    label: "Customs Control",
    id: 2,
  },
  {
    label: "Flange Surface Grinding",
    id: 3,
  },
  {
    label: "Grind",
    id: 4,
  },
  {
    label: "Grind Gate Flush",
    id: 5,
  },
  {
    label: "Heat Treat",
    id: 6,
  },
  {
    label: "Machine",
    id: 7,
  },
  {
    label: "MSI Testing",
    id: 8,
  },
  {
    label: "NDT Testing",
    id: 9,
  },
  {
    label: "Surface Grind",
    id: 10,
  },
  {
    label: "Surface Grinding",
    id: 11,
  },
  {
    label: "Surface Grinding | Diversified",
    id: 12,
  },
  {
    label: "X-Ray",
    id: 13,
  },
];

export const ROUTING_OPTIONS = [
  {
    id: 1,
    label: "Injection",
    guardian_id: 101,
    isOutside: false,
  },
  {
    id: 2,
    label: "Assembly",
    guardian_id: 102,
    isOutside: false,
  },
  {
    id: 3,
    label: "Etching",
    guardian_id: 103,
    isOutside: false,
  },
  {
    id: 4,
    label: "Dipping",
    guardian_id: 201,
    isOutside: false,
  },
  {
    id: 5,
    label: "Dipping | By Hand",
    guardian_id: 202,
    isOutside: false,
  },
  {
    id: 6,
    label: "Autoclave",
    guardian_id: 301,
    isOutside: false,
  },
  {
    id: 7,
    label: "Pouring",
    guardian_id: 401,
    isOutside: false,
  },
  {
    id: 8,
    label: "Cutoff - Robot",
    guardian_id: 501,
    isOutside: false,
  },
  {
    id: 9,
    label: "Cutoff - Chop | Friction",
    guardian_id: 502,
    isOutside: false,
  },
  {
    id: 10,
    label: "Blast - Spinner Hanger",
    guardian_id: 503,
    isOutside: false,
  },
  {
    id: 11,
    label: "Blast - Tumble",
    guardian_id: 504,
    isOutside: false,
  },
  {
    id: 12,
    label: "Welding",
    guardian_id: 505,
    isOutside: false,
  },
  {
    id: 13,
    label: "Grinding",
    guardian_id: 506,
    isOutside: false,
  },
  {
    id: 14,
    label: "Detailing",
    guardian_id: 507,
    isOutside: false,
  },
  {
    id: 15,
    label: "Heat Treat",
    guardian_id: 508,
    isOutside: false,
  },
  {
    id: 16,
    label: "Straightening",
    guardian_id: 509,
    isOutside: false,
  },
  {
    id: 17,
    label: "Blast - Sand Blasting",
    guardian_id: 535,
    isOutside: false,
  },
  {
    id: 18,
    label: "Inspection",
    guardian_id: 701,
    isOutside: false,
  },
  {
    id: 19,
    label: "Drilling & Tapping",
    guardian_id: 225,
    isOutside: false,
  },
  {
    id: 20,
    label: "Charpy Testing",
    guardian_id: "",
    reqPO: true,
    isOutside: true,
  },
  {
    id: 21,
    label: "Customs Control",
    guardian_id: "",
    isOutside: true,
  },
  {
    id: 22,
    label: "Flange Surface Grinding",
    guardian_id: "",
    isOutside: true,
  },
  {
    id: 23,
    label: "Grind",
    guardian_id: "",
    isOutside: true,
  },
  {
    id: 24,
    label: "Grind Gate Flush",
    guardian_id: "",
    isOutside: true,
  },
  {
    id: 25,
    label: "Heat Treat",
    guardian_id: "",
    isOutside: true,
    reqPO: true,
    reqRec: true
  },
  {
    id: 26,
    label: "Machine",
    guardian_id: "",
    isOutside: true,
    reqPO: true,
    reqRec: true
  },
  {
    id: 27,
    label: "MSI Testing",
    guardian_id: "",
    reqPO: true,
    isOutside: true,
  },
  {
    id: 28,
    label: "NDT Testing",
    guardian_id: "",
    reqPO: true,
    isOutside: true,
  },
  {
    id: 29,
    label: "Surface Grind",
    guardian_id: "",
    isOutside: true,
  },
  {
    id: 30,
    label: "Surface Grinding",
    guardian_id: "",
    isOutside: true,
  },
  {
    id: 31,
    label: "Surface Grinding | Diversified",
    guardian_id: "",
    isOutside: true,
  },
  {
    id: 32,
    label: "X-Ray",
    guardian_id: "",
    isOutside: true,
  },
];
