import React from "react";
import { HubActionDialog } from "../HubActionDialog";
import { rest } from "lodash";

export const WarningUpdatingWorkOrder = ({
  isOpen = false,
  onClose = () => {},
  ...rest
}) => {
  return (
    <HubActionDialog
      onClose={onClose}
      open={isOpen}
      title={"Admin Work Order Update!"}
      description={
        "You're about to update a work order that is already on the floor, please confrim you intend to make changes. This action is reserved for admins"
      }
      handleSubmit={onClose}
      submitLabel={"Confirm"}
      action="warning"
      includeCancel={false}
      {...rest}
    />
  );
};

export default WarningUpdatingWorkOrder;
