import React from "react";
import { useFormContext } from "react-hook-form";
import { TableCell, Typography } from "@mui/material";

import { HubHookFormInput } from "../../components";
import { StyledHubHookFormDatePicker, StyledTableRow } from "./styles";

const LaborTableRow = ({ labor = {}, updatedLabor = [], disabled = false }) => {
  const { setValue, getValues } = useFormContext();

  const prefix = `${labor.id}`;
  const existing = updatedLabor.find((u) => u.id === labor.id) || {};
  const rowItem = { ...labor, ...existing };

  const onFieldUpdate = () => {
    const preItem = getValues(prefix);
    let updatedItem = { ...labor, ...preItem };

    const updatedIndex = updatedLabor.findIndex((u) => u.id === labor.id);
    updatedIndex > -1
      ? (updatedLabor[updatedIndex] = updatedItem)
      : updatedLabor.push(updatedItem);

    setValue("updatedLabor", updatedLabor);
  };

  return (
    <StyledTableRow key={prefix} bgDisabled>
      <TableCell sx={{ width: 150 }}>
        <Typography variant="caption11">
          {rowItem?.employee.name || "Will Be Assigned"}
        </Typography>
      </TableCell>
      <TableCell sx={{ width: 100, minWidth: 100 }} disabled={disabled}>
        <HubHookFormInput
          name={prefix + ".completed"}
          placeholder="Completed"
          defaultValue={rowItem.completed || "0"}
          onBlur={onFieldUpdate}
          disableHelperText
          required
        />
      </TableCell>
      <TableCell sx={{ width: 100, minWidth: 100 }} disabled={disabled}>
        <HubHookFormInput
          name={prefix + ".scrapped"}
          placeholder="Scrapped"
          defaultValue={rowItem.scrapped || "0"}
          onBlur={onFieldUpdate}
          disableHelperText
          required
        />
      </TableCell>
      <TableCell sx={{ width: 100, minWidth: 100 }} disabled={disabled}>
        <StyledHubHookFormDatePicker
          name={prefix + ".startDate"}
          placeholder="MM/DD/YYYY"
          defaultValue={rowItem?.startDate}
          onChange={onFieldUpdate}
          required
        />
      </TableCell>
      <TableCell sx={{ width: 100, minWidth: 100 }} disabled={disabled}>
        <StyledHubHookFormDatePicker
          name={prefix + ".endDate"}
          placeholder="MM/DD/YYYY"
          defaultValue={rowItem?.endDate}
          onChange={onFieldUpdate}
          required
        />
      </TableCell>
      <TableCell sx={{ width: 100, minWidth: 100 }} disabled={disabled}>
        <HubHookFormInput
          name={prefix + ".notes"}
          placeholder="Notes"
          defaultValue={rowItem.notes}
          onBlur={onFieldUpdate}
          disableHelperText
        />
      </TableCell>
    </StyledTableRow>
  );
};

export default LaborTableRow;
