import React from "react";
import { Chip, Stack, Typography } from "@mui/material";

import { FOUNDRY_NAMES } from "../../constants";
import { useGetAllCustomers } from "../../hooks";
import { StyledHubHookFormSelect } from "./styles";
import { HubHookFormTagInput } from "../HubHookFormTagInput";

const sxChip = {
  fontSize: "10px !important",
  fontWeight: 400,
  height: 20,
  span: { padding: 0 },
  padding: "5px",
};

export const HubHookCustomersSelect = ({
  name,
  required = true,
  disabled,
  isTag = false,
  ...rest
}) => {
  const { data, isLoading } = useGetAllCustomers();

  const RenderComponent = ({ label, locations = [] }) => (
    <Stack direction="row" gap="10px" alignItems="center">
      <Typography
        sx={{
          fontSize: "12px !important",
          fontWeight: 500,
        }}
      >
        {label}
      </Typography>
      <Stack direction="row" gap="3px">
        {locations?.map((name) => (
          <Chip sx={sxChip} label={name} />
        ))}
      </Stack>
    </Stack>
  );

  const sharedProps = {
    RenderComponent,
    options: data || [],
    disableHelperText: true,
    required,
    isLoading,
    disabled: disabled || isLoading,
    ...rest,
  };

  return isTag ? (
    <HubHookFormTagInput
      name={name || "customerIds"}
      label="Customers"
      fullWidth
      {...sharedProps}
    />
  ) : (
    <StyledHubHookFormSelect
      name={name || "customerId"}
      label="Customer"
      useDefault={false}
      {...sharedProps}
    />
  );
};

export default HubHookCustomersSelect;
