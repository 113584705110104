import React from "react";
import { Stack, Typography } from "@mui/material";

import { formatDateMmDdYyyy } from "../../helpers/dateHelper";
import { StyledGridStack } from "./styles";

const Details = ({ label, value }) => (
  <Stack>
    <Typography variant="boldCaptionMain">{label}</Typography>
    <Typography variant="caption" sx={{ fontWeight: 600 }}>
      {value}
    </Typography>
  </Stack>
);

const LaborDetails = ({ data }) => {
  const plannedStart = new Date(data?.routing?.plannedStartDate);
  const plannedEnd = new Date(data?.routing?.plannedEndDate);
  const workOrder = data?.workOrder;

  return (
    <StyledGridStack>
      <Details label="Employee" value={data?.employee?.name} />
      <Details label="Station" value={data?.station?.label} />
      <Details
        label="Planned Start Date"
        value={formatDateMmDdYyyy(plannedStart)}
      />
      <Details
        label="Planned Due Date"
        value={formatDateMmDdYyyy(plannedEnd)}
      />
      <Details label="Quantity" value={workOrder?.quantity} />
      <Details label="Work Order" value={workOrder?.workOrder} />
      <Details label="Part" value={workOrder?.part?.partName} />
      <Details label="Parts Per Tree" value={workOrder?.part?.partsPerTree} />
      <Details label="Trees" value={workOrder?.trees} />


      <Details label="Total Completed" value={data?.routing?.completed} />
      <Details label="Total Scrapped" value={data?.routing?.scrapped} />
      <Details label="Available" value={data?.available} />
    </StyledGridStack>
  );
};

export default LaborDetails;
