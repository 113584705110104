import React from "react";
import { Stack, Typography } from "@mui/material";
import { useFormContext } from "react-hook-form";

import {
  HubHookFormInput,
  HubMaterialHookSelect,
  HubHookCustomersSelect,
} from "../../../components";

const gap = "15px";
const columnGap = "10px";

const PartMainDetails = () => {
  const { getValues } = useFormContext();
  const details = getValues("details");

  const defaultCustomers = details?.customerIds?.map((l) =>
    !isNaN(l) ? l : l?.id
  );

  return (
    <Stack gap={gap}>
      <Typography variant="boldCaptionMain">Part Details</Typography>
      <Stack direction="row" gap={columnGap}>
        <HubHookFormInput
          name="details.partName"
          label="Part Name"
          defaultValue={details?.partName}
          placeholder="ex. Pocket"
          required
        />
        <HubHookFormInput
          name="details.partNumber"
          label="Part Number"
          defaultValue={details?.partNumber}
          placeholder="ex. 29-100C"
          required
        />
      </Stack>
      <Stack direction="row" gap={columnGap}>
        <HubMaterialHookSelect
          defaultValue={details?.materialId}
          name="details.materialId"
          label="Metal Alloy"
        />
        <HubMaterialHookSelect
          type="trees"
          label="Tree Type"
          name="details.treeId"
          defaultValue={details?.treeId}
        />
        <HubHookFormInput
          name="details.partsPerTree"
          label="Parts / Tree"
          defaultValue={details?.partsPerTree}
          placeholder="Ex. 12"
          required
          isNumber
        />
      </Stack>
      <Stack direction="row" gap={columnGap}>
        <HubHookFormInput
          name="details.weight"
          label="Part Weight"
          defaultValue={details?.weight}
          placeholder="ex. 4.55"
          endAdornment="lbs"
          isDecimal
          required
        />
        <HubHookFormInput
          name="details.lastSentPrice"
          label="Price"
          defaultValue={details?.lastSentPrice}
          placeholder="ex. 4.55"
          startAdornment="$"
          isDecimal
          required
        />
        <HubHookCustomersSelect
          name="details.customerIds"
          defaultValue={defaultCustomers}
          isTag
        />
      </Stack>
    </Stack>
  );
};

export default PartMainDetails;
