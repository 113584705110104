import React, { useEffect, useState } from "react";
import { Divider, Stack, Typography } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import {
  HubCardStack,
  HubHookFormWrapper,
  PageContent,
} from "../../components";
import { useGetLaborData, usePutUpdateLabor } from "../../hooks";
import LaborDetails from "./LaborDetails";
import LaborFields from "./LaborFields";
import LaborButtonBar from "./LaborButtonBar";

export const LaborDetailsPage = () => {
  const [nav, setNav] = useState(false);

  const { id } = useParams();
  const { data, isLoading: isLoadingGet, refetch } = useGetLaborData(id);
  const {
    mutate: putUpdateLabor,
    isLoading: isLoadingPut,
    isSuccess,
  } = usePutUpdateLabor();

  const navigate = useNavigate();

  useEffect(() => {
    if (isSuccess) {
      nav ? navigate("/labor") : refetch();
    }
  }, [isSuccess]);

  const isLoading = isLoadingGet || isLoadingPut;
  const showHeat = data?.showHeat;

  const handleSubmit = (formData, event) => {
    const buttonId = event.nativeEvent.submitter.id;
    const payload = {
      id,
      scrapped: formData.scrapped,
      completed: formData.completed,
      ...(formData?.heatCode ? { heatCode: formData.heatCode } : {}),
    };

    switch (buttonId) {
      case "save":
        putUpdateLabor(payload);
        break;
      case "submit":
        setNav(true);
        payload.isClose = true;
        putUpdateLabor(payload);
      default:
        break;
    }
  };

  const scrapped = data?.scrapped;
  const completed = data?.completed;
  const heatCode = data?.heatCode;

  const props = { scrapped, completed, heatCode };
  return (
    <PageContent isLoading={isLoading}>
      <HubCardStack
        mx="auto"
        overflow="hidden"
        flexGrow="0 !important"
        maxWidth="750px"
        width="100%"
        isLoading={isLoading}
      >
        <HubHookFormWrapper onSubmit={handleSubmit}>
          <Stack gap="50px">
            <Stack gap="5px">
              <Typography variant="boldCaption">Labor Ticket #{id}</Typography>
              <Typography variant="caption">
                Add details such as scrap, complete, and heat code for labor run
              </Typography>
            </Stack>
            <Stack gap="25px">
              <LaborDetails data={data} />
              <Divider orientation="horizontal" flexItem />
              <LaborFields
                showHeat={showHeat}
                {...props}
                key={JSON.stringify(props)}
              />
            </Stack>
            <LaborButtonBar />
          </Stack>
        </HubHookFormWrapper>
      </HubCardStack>
    </PageContent>
  );
};

export default LaborDetailsPage;
