import React from "react";
import { BarChart } from "@mui/x-charts/BarChart";
import { connect } from "react-redux";

import { getOrdersCalendar } from "../../redux";
import { StyledHubCardStack } from "./styles";

import JobsChartTitleBar from "./JobsChartTitleBar";
import JobsBarChartTooltip from "./JobsBarChartTooltip";
import JobsBarTooltipDataPoint from "./JobsBarTooltipDataPoint";

const JobsBarChartData = ({ dataset = [] }) => {
  const revenueFormatter = (value) => {
    const elem = dataset.find((d) => d.revenue === value);
    const goal = elem?.dollarGoal;
    return <JobsBarTooltipDataPoint value={value} goal={goal} isMoney />;
  };

  const hourFormatter = (value) => {
    const elem = dataset.find((d) => d.hours === value);
    return <JobsBarTooltipDataPoint value={value} goal={elem.hourGoal} />;
  };

  return (
    <StyledHubCardStack padding="15px 0px 0px 0px" gap="0">
      <JobsChartTitleBar />
      <BarChart
        dataset={dataset}
        xAxis={[{ scaleType: "band", dataKey: "time" }]}
        series={[
          {
            dataKey: "revenue",
            label: "Revenue",
            color: "#CD683E",
            valueFormatter: revenueFormatter,
          },
          {
            dataKey: "hours",
            label: "Hours",
            color: "#DF9E44",
            valueFormatter: hourFormatter,
          },
        ]}
        grid={{ horizontal: true }}
        sx={{ marginLeft: "20px", marginTop: "-25px" }}
        tooltip={{ trigger: "axis", axisContent: JobsBarChartTooltip }}
        slotProps={{
          bar: {
            clipPath: `inset(0px round 5px 5px 5px 5px)`,
          },
        }}
      />
    </StyledHubCardStack>
  );
};

const mapStateToProps = (state) => ({
  dataset: getOrdersCalendar(state),
});

export const JobsBarChart = connect(mapStateToProps)(JobsBarChartData);
