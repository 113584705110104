import { WorkOrder } from "../../containers";
import WorkOrderProd from "./WorkOrderProd";

export const tabs = [
  {
    id: 1,
    label: "Routing & Labor",
    icon: "Settings",
    TabElement: WorkOrderProd,
  },
  {
    id: 2,
    label: "Work Sheet",
    icon: "FileText",
    TabElement: WorkOrder,
  },
  // {
  //   id: 3,
  //   label: "Files",
  //   icon: "Archive",
  // },
  // {
  //   id: 4,
  //   label: "Part History",
  //   icon: "Settings",
  // },
  // {
  //   id: 5,
  //   label: "Reporting",
  //   icon: "TrendingUp",
  // },
];

export const updateMapper = (formData, data) => {
  const updatePayload = {
    status: formData?.status,
  };
  const option = data?.routingOptions?.find((r) => r.id === formData.routing);
  updatePayload.route = option;

  if (option.id !== data?.currentRoute) updatePayload.changedRoute = true;
  if (formData?.updatedLabor?.length) {
    const updatedLabor = formData.updatedLabor.map((l) => ({
      id: Number(l.id),
      employeeId: Number(l.employeeId),
      completed: Number(l.completed),
      scrapped: Number(l.scrapped),
      startDate: new Date(l.startDate).toISOString(),
      endDate: new Date(l.endDate).toISOString(),
      notes: l.notes,
    }));
    updatePayload.updatedLabor = updatedLabor;
  }
  return updatePayload;
};

export const finalMapper = (payload, id) => {
  const updatePayload = { id, status: payload?.status };
  if (payload?.changedRoute) updatePayload.routeId = payload?.route?.id;
  if (payload?.updatedLabor?.length)
    updatePayload.updatedLabor = payload.updatedLabor;

  return updatePayload;
};
