import React from "react";
import { Stack, Typography } from "@mui/material";

import WorkOrderLabel from "./WorkOrderLabel";
import { StyledBaseBox, StyledWaxStack } from "./styles";

const INJECTION_TYPES = ["HAND", "TOP", "SIDE"];
const csx = { gridColumn: "span 2" };

const WorkOrderWax = ({ data }) => {
  let wax = data?.part?.waxInstructions;
  let assembly = data?.part?.assemblyInstructions;

  let totalPattern = (wax?.cycleTime * data?.quantity) / 60;
  let roundedTotalPatern = Math.round(totalPattern * 100) / 100;

  return (
    <StyledBaseBox>
      <Typography variant="boldCaptionMain">Wax Department</Typography>
      <WorkOrderLabel
        label="INJECT TYPE"
        value={INJECTION_TYPES[wax?.injectType || 0]}
      />
      <WorkOrderLabel label="SETUP DATE" underlined />

      <WorkOrderLabel label="LOT #" value={data?.lotNumber} />
      <WorkOrderLabel label="PATTERN" value={`${wax?.cycleTime} mins`} />
      <WorkOrderLabel label="SET-UP" value={data?.part?.tree?.name} />

      <WorkOrderLabel label="PATTERN DATE" underlined />
      <WorkOrderLabel
        label="TOTAL PATTERN"
        value={`${roundedTotalPatern} hrs`}
      />
      <StyledBaseBox columns={2} disablePadding disableBorder>
        <WorkOrderLabel
          label="#TREE(S)"
          value={data?.trees}
          valueBold
          c1="auto"
        />
        <WorkOrderLabel
          label="#PER TREE"
          value={data?.part?.partsPerTree}
          valueBold
          c1="auto"
        />
      </StyledBaseBox>

      <div style={csx} />
      <WorkOrderLabel label="#SET-UP TIME" />

      <WorkOrderLabel
        label="WAX INST."
        value={wax?.notes}
        gridColumn="span 2"
        c1="auto"
        valueBold
      />
      <WorkOrderLabel label="SETUP INST." value={assembly?.notes} valueBold />
    </StyledBaseBox>
  );
};

export default WorkOrderWax;
