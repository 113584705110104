import React from "react";
import { HubCardStack, PageContent } from "../../components";
import { Typography } from "@mui/material";

export const PartCustomers = () => {
  return (
    <PageContent smallPadding>
      <HubCardStack m="auto">
        <Typography variant="boldCaption">Customers</Typography>
      </HubCardStack>
    </PageContent>
  );
};

export default PartCustomers;
