import React from "react";

import { Stack, Typography } from "@mui/material";
import HubHoverInfo from "../../components/HubHoverInfo/HubHoverInfo";

const PartialCell = ({ item }) => (
  <Stack direction="row" alignItems="center" gap="5px">
    <Typography variant="caption">{item.available}</Typography>
    {item.isPartial && (
      <HubHoverInfo
        label={
          <Typography variant="caption">
            This routing is a partial, more parts may be coming.
          </Typography>
        }
        icon="LogIn"
        iconProps={{
          size: 14,
          sx: { color: "hubColors.mainFocus", "svg, path": { strokeWidth: 3 } },
        }}
        isCard
      />
    )}
  </Stack>
);

export default PartialCell;
