import React from "react";
import { TableContainer, Table } from "@mui/material";

import LaborTableHeader from "./LaborTableHeader";
import LaborTableBody from "./LaborTableBody";

export const LaborTable = ({ item }) => {
  const labor = item?.labor || [];

  return (
    <TableContainer sx={{ backgroundColor: "hubColors.white" }}>
      <Table id="CustomerOrdersTable">
        <LaborTableHeader />
        <LaborTableBody labor={labor} />
      </Table>
    </TableContainer>
  );
};

export default LaborTable;
