import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useController, useFormContext } from "react-hook-form";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import { Checkbox, Stack, Typography, TextField, Chip } from "@mui/material";

import { EMAIL_REGEX } from "../../utils/validations";
import { inputLabelStyles } from "../inputs/HubTagInput/styles";

import { HubIcon } from "../HubIcon";
import { InputLabel } from "../InputLabel";

import { StyledFormControl } from "./styles";

export const HubHookFormTagInput = ({
  name,
  label,
  placeholder,
  options = [],
  multi = true,
  isEmail = false,
  required = false,
  disableHelperText = true,
  onChange = () => {},
  customFilterTag = "Add",
  startAdornmentIcon,
  defaultValue = [],
  allowAdd = false,
  hidden,
  fullWidth,
  RenderComponent,
  ...rest
}) => {
  const id = `hub-hook-autocomplete-${name}`;
  const { setValue, register } = useFormContext();
  const {
    field: { onChange: onControllerChange, ref, value, name: inputName },
    fieldState: { error },
  } = useController({
    name,
    defaultValue: [],
    shouldUnregister: true,
    rules: {
      required,
      ...(isEmail
        ? {
            validate: (value) =>
              value.every((val) => EMAIL_REGEX.test(val.label || val)) ||
              "Please enter a valid email address",
          }
        : {}),
    },
  });

  // Set default value based on the passed defaultValue prop (array of ids)
  useEffect(() => {
    if (defaultValue.length > 0 && options.length > 0) {
      const initialTags = defaultValue
        .map((id) => options.find((option) => option.id === id))
        .filter(Boolean); // Filter out undefined values
      setValue(inputName, initialTags);
    } // eslint-disable-next-line
  }, [defaultValue, options]);

  const filter = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option.label,
  });

  const filterOptions = (options, params) => {
    const filtered = filter(options, params);

    const { inputValue } = params;
    const isExisting = options.some((option) => inputValue === option?.label);

    if (allowAdd && inputValue !== "" && !isExisting) {
      filtered.push({
        id: inputValue,
        label: inputValue,
      });
    }

    return filtered;
  };

  const handleRules = (f) => {
    if (isEmail) {
      return EMAIL_REGEX.test(f.label);
    }
    return true;
  };

  const handleOnChange = (event, newValue = []) => {
    const result = Array.from(new Set(newValue?.map((o) => o.id)))
      .map((id) => ({
        id,
        label: newValue?.find((f) => f?.id === id)?.label,
      }))
      .filter(handleRules);

    onControllerChange(result);
    onChange(result);
  };

  const renderOption = (props, option, { selected }) => {
    return (
      <li {...props}>
        <Stack
          direction="row"
          gap="10px"
          alignItems="center"
          justifyContent="center"
        >
          {multi && (
            <Checkbox
              checked={selected}
              size="15px"
              sx={{ padding: 0, margin: 0 }}
            />
          )}
          {RenderComponent ? (
            <RenderComponent {...option} />
          ) : (
            <Typography variant="caption" sx={{ fontWeight: 400 }}>
              {option?.label}
            </Typography>
          )}
        </Stack>
      </li>
    );
  };

  const renderTags = (value, getTagProps) =>
    value.map(
      (option, index) =>
        option && (
          <Chip
            key={option?.id || index}
            label={option?.label}
            sx={{ gap: "5px", svg: { width: "15px" } }}
            {...getTagProps({ index })}
          />
        )
    );

  const renderInput = (params) => {
    const props = {
      id,
      error: !!error,
      placeholder: !value?.length ? placeholder : "",
      helperText: !disableHelperText && error ? error.message : null,
      inputRef: ref,
      InputLabelProps: {
        shrink: false,
        sx: inputLabelStyles,
      },
      InputProps: {
        ...params?.InputProps,
        startAdornment:
          !value?.length && startAdornmentIcon ? (
            <HubIcon
              icon={startAdornmentIcon}
              sx={{
                color: "hubColors.darkGray1",
                width: "15px",
                padding: "0 5px",
              }}
            />
          ) : (
            params?.InputProps?.startAdornment
          ),
      },
    };
    return <TextField {...params} variant="outlined" {...props} />;
  };

  return (
    <StyledFormControl hidden={hidden} fullWidth={fullWidth}>
      <InputLabel label={label} required={required} id={id} />
      <Autocomplete
        options={options}
        getOptionLabel={(option) => option?.label || option}
        freeSolo={isEmail}
        value={value || []}
        filterOptions={filterOptions}
        renderOption={renderOption}
        renderTags={renderTags}
        renderInput={renderInput}
        onChange={handleOnChange}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        autoHighlight
        disableCloseOnSelect
        multiple
        {...rest}
      />
      <input {...register(name)} type="input" style={{ display: "none" }} />
    </StyledFormControl>
  );
};

HubHookFormTagInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.array,
  isEmail: PropTypes.bool,
  onChange: PropTypes.func,
  defaultValue: PropTypes.array, // Ensure prop type for defaultValue
};

export default HubHookFormTagInput;
