import React from "react";

import { useGetDisplayDashboard } from "../../hooks";
import { HubTableTemplate } from "../../components";
import { condition } from "./helper";
import { StyledGridRow } from "./styles";
import DisplayTableContainer from "./DisplayTableContainer";

const sortable = true;
const items = [
  {
    id: "startDate",
    label: "Inspection Date",
    sortable,
  },
  {
    id: "part",
    label: "Part",
  },
  {
    id: "workOrder",
    label: "WO #",
    sortable,
  },
  {
    id: "lotNumber",
    label: "Lot",
  },
  {
    id: "available",
    label: "Available",
  },
  {
    id: "remaining",
    label: "Remaining",
  },
  {
    id: "station",
    label: "Station",
  },
];

const keys = [
  {
    value: "startDate",
    isDate: true,
  },
  {
    variant: "blueBold",
    value: "partName",
    navigate: true,
    route: "parts",
    id: "partId",
  },
  {
    variant: "blueBold",
    value: "workOrder",
  },
  {
    value: "lotNumber",
  },
  {
    value: "Available",
    defaultValue: 0,
  },
  {
    value: "remaining",
    defaultValue: 0,
  },
  {
    value: "station",
  },
];

const InspectionTable = ({ setWorkOrder = () => {}, defaultLocation }) => {
  const { data } = useGetDisplayDashboard({ labor: false, defaultLocation });

  const onClick = ({ workOrder, rid }) => setWorkOrder({ workOrder, rid });

  const modifiedKeys = keys.map((key) =>
    key.value === "workOrder" ? { ...key, onClick } : key
  );

  const headerProps = { items, includeCheckbox: false, includeEnd: false };
  const bodyProps = { keys: modifiedKeys, condition };

  const hasDisks = !!data?.disks?.count;

  return (
    <StyledGridRow full={hasDisks}>
      <DisplayTableContainer
        title={data?.rows?.title}
        count={data?.rows?.count}
      >
        <HubTableTemplate
          id="NonDiskInspection"
          headerProps={headerProps}
          bodyProps={{ ...bodyProps, items: data?.rows?.rows }}
        />
      </DisplayTableContainer>
      {hasDisks && (
        <DisplayTableContainer
          title={data?.disks?.title}
          count={data?.disks?.count}
        >
          <HubTableTemplate
            id="DiskInspection"
            headerProps={headerProps}
            bodyProps={{ ...bodyProps, items: data?.disks?.rows }}
          />
        </DisplayTableContainer>
      )}
    </StyledGridRow>
  );
};

export default InspectionTable;
