import { TableBody } from "@mui/material";
import React from "react";
import { useFormContext } from "react-hook-form";
import LaborTableRow from "./LaborTableRow";

const LaborTableBody = ({ labor = [] }) => {
  const { watch } = useFormContext();
  const updatedLabor = watch("updatedLabor") || [];

  return (
    <TableBody>
      {labor?.map((item) => {
        const payload = { labor: item, updatedLabor };
        return <LaborTableRow key={JSON.stringify(item)} {...payload} />;
      })}
    </TableBody>
  );
};

export default LaborTableBody;
