import React, { useEffect } from "react";
import { TableBody } from "@mui/material";

import { JOB_STATUSES, ROLES } from "../../constants";
import OrderLineItemRow from "./OrderLineItemRow";
import { useFormContext } from "react-hook-form";
import { connect } from "react-redux";
import { getUserRole } from "../../redux";

const OrderLineItemsBody = ({ items = [], part = {}, role }) => {
  const { setValue, register, watch } = useFormContext();

  useEffect(() => {
    register("deletedWorkOrders");
    setValue("deletedWorkOrders", []);
  }, [register]);

  const deletedValues = watch("deletedWorkOrders") || [];
  const updatedItems = watch("updatedWorkOrders") || [];
  const itemPayload = { part, deletedValues, updatedItems };

  return (
    <TableBody>
      {items?.map((item, idx) => {
        let disabled = item?.state != JOB_STATUSES.NOT_STARTED && item?.state;
        let warnChange = false;
        if (role === ROLES.Admin && disabled) {
          disabled = false;
          warnChange = true;
        }
        const payload = { item, idx, disabled, ...itemPayload, warnChange };
        return <OrderLineItemRow key={JSON.stringify(item)} {...payload} />;
      })}
    </TableBody>
  );
};

const mapStateToProps = (state) => ({
  role: getUserRole(state),
});
export default connect(mapStateToProps)(OrderLineItemsBody);
