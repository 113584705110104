import React from "react";
import { HubTableTemplate } from "../../components";

import WorkOrderRoutingLabor from "./WorkOrderRoutingLabor";
import { condition } from "./helper";

const items = [
  {
    id: "complete",
    label: "",
  },
  {
    id: "station",
    label: "Station",
  },
  {
    id: "material",
    label: "Material",
  },
  {
    id: "completed",
    label: "Completed",
  },
  {
    id: "scrapped",
    label: "Scrapped",
  },
  {
    id: "plannedStartDate",
    label: "Planned Start",
  },
  {
    id: "plannedEndDate",
    label: "Planned End",
  },
  {
    id: "startDate",
    label: "Actual Start",
  },
  {
    id: "endDate",
    label: "Actual End",
  },
];

const keys = [
  {
    value: "isDone",
    defaultValue: false,
    isCheckbox: true,
  },
  {
    value: "station.label",
  },
  {
    value: "material.name",
  },
  {
    value: "completed",
    defaultValue: 0,
  },
  {
    value: "scrapped",
    defaultValue: 0,
  },
  {
    value: "plannedStartDate",
    isDate: true,
  },
  {
    value: "plannedEndDate",
    isDate: true,
  },
  {
    value: "startDate",
    isDate: true,
  },
  {
    value: "endDate",
    isDate: true,
  },
];

export const WorkOrderRoutingTable = ({ routes, current }) => {
  const rows = routes?.map((r) => ({
    ...r,
    isCurrent: r?.id === current,
    isDone: r?.endDate,
  }));

  const headerProps = { items };
  const bodyProps = {
    items: rows,
    keys,
    condition,
    RenderElement: WorkOrderRoutingLabor,
  };
  const props = { headerProps, bodyProps };

  return <HubTableTemplate id="WorkOrderRouting" {...props} />;
};

export default WorkOrderRoutingTable;
