import React from "react";

import { useGetDisplayDashboard } from "../../hooks";
import { HubTableTemplate } from "../../components";
import { condition } from "./helper";
import PartialCell from "./PartialCell";

const sortable = true;
const items = [
  {
    id: "startDate",
    label: "Start Date",
    sortable,
  },

  {
    id: "part",
    label: "Part",
  },
  {
    id: "workOrder",
    label: "WO #",
    sortable,
  },
  {
    id: "lotNumber",
    label: "Lot",
  },
  {
    id: "available",
    label: "Available",
  },
  {
    id: "remaining",
    label: "Remaining",
  },
  {
    id: "station",
    label: "Station",
  },
  {
    id: "material",
    label: "Material",
  },
  {
    id: "startTime",
    label: "Start Time",
  },
];

const keys = [
  {
    value: "startDate",
    isDate: true,
  },
  {
    variant: "blueBold",
    value: "partName",
    navigate: true,
    route: "parts",
    id: "partId",
  },
  {
    value: "workOrder",
    variant: "blueBold",
  },
  {
    value: "lotNumber",
  },
  {
    value: "available",
    defaultValue: 0,
    CustomComponent: PartialCell,
  },
  {
    value: "remaining",
    defaultValue: 0,
  },
  {
    value: "station",
  },
  {
    value: "material.name",
  },
  {
    value: "actualStartDate",
    isDateTime: true,
  },
];

const StandardTable = ({
  setWorkOrder = () => {},
  defaultLocation,
  includeMaterial = false,
}) => {
  const { data } = useGetDisplayDashboard({ labor: false, defaultLocation });

  const onClick = ({ workOrder, rid }) => setWorkOrder({ workOrder, rid });

  const modifiedKeys = keys.map((key) =>
    key.value === "workOrder" ? { ...key, onClick } : key
  );

  const filteredKeys = includeMaterial
    ? modifiedKeys
    : modifiedKeys?.filter((k) => k.value !== "material.name");

  const filteredItems = includeMaterial
    ? items
    : items.filter((i) => i.id !== "material");

  const headerProps = { items: filteredItems };
  const bodyProps = { items: data?.rows, keys: filteredKeys, condition };
  const props = { headerProps, bodyProps };

  return <HubTableTemplate id="DisplayTable" {...props} />;
};

export default StandardTable;
